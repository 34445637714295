import React, { useContext } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { PagePropsType, lang } from '../../entities/countryIndexQueries'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { SiteContext } from '../../Context/site'
import translations from '../../entities/translations';
import PortableText from '../../components/sanityBlockContents/PortableText'
import Text from '../../components/atoms/Text';
import PageSection from '../../components/organism/HomePageComponent';
import Carousel from '../../components/organism/carousel'
import {Card} from '../../components/molecule/Card'
import { imageURLBuilder, getCardSize } from '../../utils';

const IndexPage:React.FC<PagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.highlights  })
  const { state } = useContext(SiteContext);
  const buttonTransaltion = translations.globals.buttons;
  const pageID = translations.globals.subMenuTranslation;

  return (
    <Layout seoTitle={page?.metadata?.page_title[state?.lang as lang] as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : '' },
    ]}>
      <Wrapper className="py-12">
      { page?.tripsIntro && page?.tripsIntro[state?.lang as lang]
        && 
        <PortableText content={page?.tripsIntro[state?.lang as lang]} 
          className=""
          template={{
            h2: (props: any) => <Text type="H2">{props.children}</Text>,
            normal: (props: any) => <Text type="P" ptype="BASE"  >{props.children}</Text>,
            blockquote: (props: any) => <Text type="P" ptype="BASE">{props.children}</Text>,
          }} />}
      </Wrapper>

      <PageSection
        readMoreLink={"#"}
        sanityContext={null}
        id={pageID.Reisen[state?.lang as lang]!}
        title={''} >
        <Carousel arrayLength={page.trips?.length || 0}>
         {page.trips?.map((item, i: number, arr) => <Card
          key={i}
          _className="mt-2 mx-2.5 w-10/12 mb-4 opacity-20 md:opacity-100"
          imageAlt={item.tripImage && item?.tripImage?.alt[state?.lang as lang]}
          imageUrl={imageURLBuilder(item?.tripImage?.image).url()}
          title={item.title[state?.lang as lang] || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="COUNTRY"
          cardSize={getCardSize(arr)}
          /> )}
        </Carousel>
      </PageSection>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryTripsPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      countryImage: _rawCountryImage(resolveReferences: {maxDepth: 2})
      tripsIntro:_rawTripsIntro(resolveReferences: {maxDepth: 4})
      trips: _rawTrips(resolveReferences: {maxDepth: 5})
      metadata: _rawCountryTripsMetadata
    }
  }
`
